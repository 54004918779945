import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useCart } from "../context/CartContext";
import CartBadge from "./CartBadge";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faShoppingCart,
  faUser,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";

const BottomMenu = ({ selectedFridgeId }) => {
  const { cartItems } = useCart();
  const cartCount = cartItems.reduce((acc, item) => acc + item.quantity, 0);
  const navigate = useNavigate();

  console.log("selectedFridgeId passed to BottomMenu: ", selectedFridgeId);

  return (
    <Container>
      <MenuItem onClick={() => navigate("/products")}>
        <FontAwesomeIcon icon={faHome} size="lg" color="#FFF" />
      </MenuItem>
      <MenuItem
        onClick={() => {
          navigate("/cart", {
            state: { selectedFridgeId },
          });
        }}
      >
        <IconContainer>
          <FontAwesomeIcon icon={faShoppingCart} size="lg" color="#FFF" />
          {cartCount > 0 && <CartBadge count={cartCount} />}
        </IconContainer>
      </MenuItem>
      <MenuItem onClick={() => navigate("/account")}>
        <FontAwesomeIcon
          icon={faUser}
          size="lg"
          color="#FFF"
          onClick={() => {
            navigate("/account");
          }}
        />
      </MenuItem>
      <MenuItem>
        <FontAwesomeIcon
          icon={faEnvelope}
          size="lg"
          color="#FFF"
          onClick={() => {
            navigate("/contact");
          }}
        />
      </MenuItem>
    </Container>
  );
};

export default BottomMenu;

const Container = styled.div`
  position: fixed;
  bottom: 15px;
  left: 15px;
  right: 15px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: rgba(26, 26, 26, 0.85);
  padding: 10px;
  border-radius: 100px;
  z-index: 1000;
`;

const MenuItem = styled.div`
  cursor: pointer;
`;

const IconContainer = styled.div`
  position: relative;
`;
