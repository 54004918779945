import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useAuth } from "../context/AuthContext";
import { useNavigate } from "react-router-dom";
import boxmealLogoSrc from "../assets/images/logo.png";
import BottomMenu from "../components/BottomMenu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComments } from "@fortawesome/free-solid-svg-icons";

const AccountScreen = () => {
  const { user, token, logout, setUser } = useAuth();
  const navigate = useNavigate();
  const [selectedFridge, setSelectedFridge] = useState();

  useEffect(() => {
    if (!token) {
      console.log("No token found, redirecting to home");
      navigate("/");
    }
  }, [token, navigate]);

  const handleLogout = () => {
    logout();
    navigate("/");
  };

  const handlePaymentHistoryClick = () => {
    navigate("/paymenthistory", { state: { user } });
  };

  return (
    <Container>
      <TopLogoContainer>
        <TopLogo src={boxmealLogoSrc} alt="Boxmeal Logo" />
      </TopLogoContainer>
      <CardContainer>
        <ContactCard onClick={handlePaymentHistoryClick}>
          <IconContainer>
            <FontAwesomeIcon icon={faComments} size="2x" />
          </IconContainer>
          <TextContainer>
            <CardTitle>My Payment History</CardTitle>
            <CardDescription>Get full shop and payment history</CardDescription>
          </TextContainer>
        </ContactCard>
      </CardContainer>
      <ContentContainer>
        {user ? (
          <>
            <UserInfo>
              <UserName>
                {user.firstName} {user.lastName}
              </UserName>
              <UserEmail>{user.email}</UserEmail>
            </UserInfo>
          </>
        ) : (
          <LoadingMessage>No user found</LoadingMessage>
        )}

        <LogoutButton onClick={handleLogout}>Logga ut</LogoutButton>
      </ContentContainer>
      <BottomMenuContainer>
        <BottomMenu selectedFridgeId={selectedFridge} />
      </BottomMenuContainer>
    </Container>
  );
};

export default AccountScreen;

// Styled components...

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-height: 100vh;
  background-color: #1a1a1a;
  padding: 20px;
`;

const TopLogoContainer = styled.div`
  display: grid;
  margin-top: 50px;
  margin-bottom: 50px;
  justify-items: center;
`;

const TopLogo = styled.img`
  width: 60%;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 400px;
`;

const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
  // background-color: #252525;
  padding: 20px;
  border-radius: 12px;
  width: 100%;
`;

const UserName = styled.h2`
  color: #ffffff;
  font-size: 24px;
  margin-bottom: 8px;
  font-weight: 500;
`;

const UserEmail = styled.p`
  color: #bbbbbb;
  font-size: 18px;
`;

const LogoutButton = styled.button`
  background-color: #3fc48a;
  padding: 15px 30px;
  border-radius: 25px;
  color: #ffffff;
  font-size: 16px;
  font-weight: bold;
  width: 100%;
  max-width: 300px;
  cursor: pointer;
  border: none;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: #34a972;
  }
`;

const LoadingMessage = styled.p`
  color: #ffffff;
  font-size: 18px;
`;

const BottomMenuContainer = styled.div`
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
`;

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
  margin-bottom: 60px;
`;

const ContactCard = styled.div`
  background-color: #252525;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  padding: 20px;
  display: flex;
  align-items: center;
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  cursor: pointer;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.8);
  }
`;

const IconContainer = styled.div`
  margin-right: 20px;
  color: #3fc48a;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const CardTitle = styled.h2`
  font-size: 20px;
  margin: 0;
  color: #3fc48a;
`;

const CardDescription = styled.p`
  font-size: 16px;
  margin: 5px 0 0;
  color: #ffffff;
`;
