import React from "react";
import styled from "styled-components";
import CartItem from "../components/CartItem";
import { useCart } from "../context/CartContext";
import { useLocation, useNavigate } from "react-router-dom";

export default function CartScreen() {
  const { cartItems, setCartItems } = useCart();
  const navigate = useNavigate();
  const location = useLocation();

  const { selectedFridgeId } = location.state || {};

  console.log("selectedFridgeId in CartScreen: ", selectedFridgeId);

  const handleRemove = (itemId) => {
    const updatedCartItems = cartItems.filter((item) => item.id !== itemId);
    setCartItems(updatedCartItems);
  };

  const calculateTotal = () => {
    return cartItems
      .reduce((acc, item) => acc + item.price * item.quantity, 0)
      .toFixed(2);
  };

  return (
    <Container>
      <GoBackButton onClick={() => navigate(-1)}>Tillbaka</GoBackButton>
      {cartItems.length > 0 ? (
        <>
          <ItemList>
            {cartItems.map((item) => (
              <CartItem
                key={item.id}
                imageURL={item.imageURL}
                title={item.title}
                price={item.price}
                quantity={item.quantity}
                onRemove={() => handleRemove(item.id)}
              />
            ))}
          </ItemList>
          <StickyFooter>
            <TotalContainer>
              <TotalText>Totalt: {calculateTotal()} kr</TotalText>
              <CheckoutButton
                onClick={() =>
                  navigate("/choose-payment", { state: { selectedFridgeId } })
                }
              >
                Betala
              </CheckoutButton>
            </TotalContainer>
          </StickyFooter>
        </>
      ) : (
        <EmptyCartContainer>
          <EmptyCartText>Din varukorg är tom</EmptyCartText>
          <CheckoutButton onClick={() => navigate(-1)}>Tillbaka</CheckoutButton>
        </EmptyCartContainer>
      )}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #1a1a1a;
`;

const GoBackButton = styled.button`
  background-color: #3fc48a;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  padding: 10px 30px;
  border-radius: 25px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
  margin: 20px;
  align-self: flex-start;

  &:hover {
    background-color: #34a972;
  }
`;

const ItemList = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: 20px;
`;

const StickyFooter = styled.div`
  position: sticky;
  bottom: 0;
  width: 100%;
  background-color: #252525;
  padding: 20px;
  border-top: 1px solid #3fc48a;
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.2);
`;

const TotalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TotalText = styled.div`
  color: #fff;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 15px;
`;

const CheckoutButton = styled.button`
  background-color: #3fc48a;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  padding: 15px 50px;
  border-radius: 30px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #34a972;
  }
`;

const EmptyCartContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 200px;
`;

const EmptyCartText = styled.div`
  color: #fff;
  font-size: 18px;
  text-align: center;
  margin-bottom: 20px;
`;
