import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";

const ProtectedRoute = ({ element: Component }) => {
  const { token, loading } = useAuth(); // Access loading state from AuthContext

  console.log("[ProtectedRoute] Checking access. Token:", token);

  if (loading) {
    return <div>Loading...</div>; // Show a loader while checking the session
  }

  if (!token) {
    console.log("[ProtectedRoute] No token found, redirecting to home");
    return <Navigate to="/" replace />;
  }

  console.log("[ProtectedRoute] Token found, rendering protected component");
  return <Component />;
};

export default ProtectedRoute;
