import React from "react";
import styled from "styled-components";

const CartBadge = ({ count }) => {
  return (
    <Badge>
      <BadgeText>{count}</BadgeText>
    </Badge>
  );
};

export default CartBadge;

const Badge = styled.div`
  position: absolute;
  top: -10px;
  right: -10px;
  background-color: red;
  border-radius: 8px;
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const BadgeText = styled.span`
  color: white;
  font-size: 12px;
`;
