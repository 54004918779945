import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import * as signalR from "@microsoft/signalr";
import {
  fetchMessagesByUserId,
  sendReply,
  createSession,
  getSessionByUserId,
} from "../services/chatService";
import { useAuth } from "../context/AuthContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";

const ChatPage = () => {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [connection, setConnection] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [sessionId, setSessionId] = useState(null);
  const { user, token } = useAuth();
  const chatContainerRef = useRef(null); // Reference for the chat container

  useEffect(() => {
    if (!user || !token) {
      setError("User not authenticated");
      setLoading(false);
      return;
    }

    const getMessages = async () => {
      try {
        const fetchedMessages = await fetchMessagesByUserId(
          token,
          user.normalizedEmail
        );
        console.log("Fetched Messages: ", fetchedMessages);
        setMessages(fetchedMessages);

        const sessions = await getSessionByUserId(token, user.normalizedEmail);
        if (sessions.length > 0) {
          setSessionId(sessions[0].id);
        } else {
          const newSession = await createSession(token, user.normalizedEmail);
          setSessionId(newSession.id);
        }

        setLoading(false);
      } catch (err) {
        setError("Failed to fetch messages or session.");
        setLoading(false);
      }
    };

    getMessages();
  }, [token, user]);

  useEffect(() => {
    if (!user || !token || !sessionId) {
      return;
    }

    const connectSignalR = async () => {
      try {
        const conn = new signalR.HubConnectionBuilder()
          .withUrl("https://boxmealadminapinew.azurewebsites.net/chatHub", {
            accessTokenFactory: () => token,
          })
          .withAutomaticReconnect()
          .build();

        conn.on("ReceiveMessage", (userName, message) => {
          console.log("ReceiveMessage triggered", { userName, message });
          setMessages((prevMessages) => {
            const updatedMessages = [
              ...prevMessages,
              {
                userName,
                message,
                timestamp: new Date().toISOString(),
                userId: user.id,
              },
            ];
            return updatedMessages;
          });
          scrollToBottom(); // Scroll to the bottom after receiving a new message
        });

        conn.on("ReceiveReply", (userName, message) => {
          console.log("ReceiveReply triggered", { userName, message });
          setMessages((prevMessages) => [
            ...prevMessages,
            {
              userName,
              message,
              timestamp: new Date().toISOString(),
              userId: user.id,
            },
          ]);
          scrollToBottom(); // Scroll to the bottom after receiving a reply
        });

        await conn.start();
        setConnection(conn);

        await conn.invoke("JoinGroup", sessionId);
        console.log("Joined group:", sessionId);
      } catch (err) {
        console.error("Failed to connect to chat server:", err);
        setError("Failed to connect to chat server");
        setConnection(null);
      }
    };

    connectSignalR();

    return () => {
      if (connection) {
        connection.stop().then(() => console.log("Connection stopped"));
      }
    };
  }, [token, user, sessionId]);

  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  };

  const handleSendMessage = async () => {
    if (newMessage.trim() === "" || !sessionId || !user || !token) return;

    const messageObject = {
      sessionId,
      userName: user.normalizedEmail,
      userId: user.id,
      message: newMessage,
      timestamp: new Date().toISOString(),
    };

    // Add the message to the state immediately (optimistic update)
    setMessages((prevMessages) => [...prevMessages, messageObject]);

    // Clear the input field
    setNewMessage("");

    // Scroll to bottom after sending the message
    scrollToBottom();

    try {
      // Send message to the server
      await sendReply(messageObject, token);
    } catch (err) {
      console.error("Failed to send message:", err);
      setError("Failed to send message");
      // Optionally, remove the message from the UI or show a warning
    }
  };

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    const formattedDate = date.toISOString().split("T")[0];
    const formattedTime = date.toTimeString().split(" ")[0].slice(0, 5);

    return { formattedDate, formattedTime };
  };

  useEffect(() => {
    scrollToBottom(); // Scroll to the bottom when the component mounts and messages are loaded
  }, [messages]);

  if (loading) {
    return <Container>Loading...</Container>;
  }

  if (error) {
    return (
      <Container>
        <ErrorMessage>{error}</ErrorMessage>
      </Container>
    );
  }

  return (
    <Container>
      <Header>
        <Title>Support Chat</Title>
      </Header>
      <ChatContainer ref={chatContainerRef}>
        {messages
          .slice() // create a shallow copy to avoid mutating the original state
          .sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp)) // Sort messages by timestamp
          .filter((message) => message.userName !== "System") // Exclude system messages
          .map((message, index) => {
            const { formattedDate, formattedTime } = formatDate(
              message.timestamp
            );

            const isSystemMessage = message.userName === "System";
            const isAdminMessage = message.userName === "admin@boxmeal.se";
            const isUserMessage = !isSystemMessage && !isAdminMessage;

            return (
              <MessageWrapper
                key={index}
                isAdminMessage={isAdminMessage}
                isSystemMessage={isSystemMessage}
                isUserMessage={isUserMessage}
              >
                <MessageContent
                  isAdminMessage={isAdminMessage}
                  isSystemMessage={isSystemMessage}
                >
                  <MessageText>{message.message}</MessageText>
                  <TimestampContainer>
                    <Timestamp>{formattedTime}</Timestamp>
                    <DateStamp>{formattedDate}</DateStamp>
                  </TimestampContainer>
                </MessageContent>
              </MessageWrapper>
            );
          })}
      </ChatContainer>

      <InputContainer>
        <MessageInput
          type="text"
          placeholder="Type your message..."
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          onKeyPress={(e) => e.key === "Enter" && handleSendMessage()}
        />
        <SendButton onClick={handleSendMessage}>
          <FontAwesomeIcon icon={faPaperPlane} size="lg" />
        </SendButton>
      </InputContainer>
    </Container>
  );
};

export default ChatPage;

// Styled components

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: #1a1a1a;
  color: #ffffff;
  height: 100vh;
  width: 100%;
  max-width: 600px;
  margin: auto;
  padding: 20px;
  box-sizing: border-box;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 20px;
`;

const Title = styled.h1`
  font-size: 24px;
  color: #3fc48a;
`;

const ChatContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #252525;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  overflow-y: auto;
  flex-grow: 1;
  margin-bottom: 20px;
`;

const MessageWrapper = styled.div`
  display: flex;
  justify-content: ${({ isAdminMessage }) =>
    isAdminMessage ? "flex-end" : "flex-start"};
  margin-bottom: 10px;
  width: 100%;
`;

const MessageContent = styled.div`
  background-color: ${({ isSystemMessage, isAdminMessage }) =>
    isSystemMessage ? "#3fc48a" : isAdminMessage ? "#3fc48a" : "#444"};
  color: ${({ isSystemMessage, isAdminMessage }) =>
    isSystemMessage || isAdminMessage ? "#000" : "#fff"};
  padding: 12px 16px;
  border-radius: 20px;
  min-width: 70%;
  max-width: 70%;
  word-wrap: break-word;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  position: relative;
`;

const MessageText = styled.div`
  font-size: 16px;
  margin-bottom: 10px;
`;

const TimestampContainer = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  color: #bbb;
`;

const Timestamp = styled.div`
  margin-top: 10px;
`;

const DateStamp = styled.div`
  margin-top: 10px;
`;

const InputContainer = styled.div`
  display: flex;
  width: 100%;
  background-color: #252525;
  padding: 12px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
`;

const MessageInput = styled.textarea`
  flex-grow: 1;
  padding: 12px;
  border: none;
  border-radius: 5px;
  background-color: #2c2c2c;
  color: #ffffff;
  font-size: 16px;
  outline: none;
  resize: none;
  height: 80px;
`;

const SendButton = styled.button`
  background-color: #3fc48a;
  border: none;
  border-radius: 5px;
  padding: 12px;
  margin-left: 10px;
  cursor: pointer;
  color: #ffffff;

  &:hover {
    background-color: #34b380;
  }
`;

const ErrorMessage = styled.p`
  color: #ff4d4d;
  text-align: center;
  font-size: 14px;
`;
