import styled from "styled-components";
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import OnboardingScreenVideo from "./pages/OnboardingScreenVideo";
import ProductsScreen from "./pages/ProductSreen";
import CartScreen from "./pages/CartScreen";
import ChoosePayment from "./pages/ChoosePaymentScreen";
import RegistrationScreen from "./pages/RegistrationScreen";
import QRCodeScreen from "./pages/QRCodeScreen";
import AccountScreen from "./pages/AccountScreen";
import { AuthProvider } from "./context/AuthContext";
import ProtectedRoute from "./components/ProtectedRoute";
import Layout from "./components/Layout";
import PaymentHistory from "./pages/PaymentHistory";
import ContactPage from "./pages/ContactPage";
import ChatPage from "./pages/ChatPage";
import EmailPage from "./pages/EmailPage";

function App() {
  return (
    <Router>
      <AuthProvider>
        <Routes>
          <Route path="/" element={<OnboardingScreenVideo />} />
          <Route
            path="/products"
            element={<ProtectedRoute element={ProductsScreen} />}
          />
          <Route
            path="/cart"
            element={<ProtectedRoute element={CartScreen} />}
          />
          <Route
            path="/choose-payment"
            element={<ProtectedRoute element={ChoosePayment} />}
          />
          <Route path="/registration" element={<RegistrationScreen />} />
          <Route
            path="/qrcode"
            element={<ProtectedRoute element={QRCodeScreen} />}
          />
          <Route
            path="/account"
            element={<ProtectedRoute element={AccountScreen} />}
          />
          <Route
            path="/paymenthistory"
            element={<ProtectedRoute element={PaymentHistory} />}
          />
          <Route
            path="/contact"
            element={<ProtectedRoute element={ContactPage} />}
          />
          <Route path="/chat" element={<ProtectedRoute element={ChatPage} />} />
          <Route
            path="/email"
            element={<ProtectedRoute element={EmailPage} />}
          />
        </Routes>
      </AuthProvider>
    </Router>
  );
  // }
  // return (
  //   <Router>
  //     <Routes>
  //       <Route path="/" element={<OnboardingScreenVideo />} />
  //       <Route path="/products" element={<ProductsScreen />} />
  //       <Route path="/cart" element={<CartScreen />} />
  //       <Route path="/choose-payment" element={<ChoosePayment />} />
  //       <Route path="/registration" element={<RegistrationScreen />} />
  //       <Route path="/qrcode" element={<QRCodeScreen />} />
  //       <Route path="/account" element={<AccountScreen />} />
  //       <Route path="/paymenthistory" element={<PaymentHistory />} />
  //       <Route path="/contact" element={<ContactPage />} />
  //       <Route path="/chat" element={<ChatPage />} />
  //       <Route path="/email" element={<EmailPage />} />
  //     </Routes>
  //   </Router>
  // );
}

export default App;
