import React, { createContext, useContext, useState, useEffect } from "react";

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(null);
  const [loading, setLoading] = useState(true); // Add a loading state

  useEffect(() => {
    const savedToken = localStorage.getItem("authToken");
    const savedUser = localStorage.getItem("userDetails");

    if (savedToken) {
      console.log("[AuthContext] Token found in localStorage:", savedToken);
      setToken(savedToken);
      if (savedUser) {
        console.log(
          "[AuthContext] User found in localStorage:",
          JSON.parse(savedUser)
        );
        setUser(JSON.parse(savedUser));
      }
    } else {
      console.log("[AuthContext] No token found in localStorage");
    }

    setLoading(false); // Session restoration complete
  }, []);

  const login = (userDetails, authToken) => {
    console.log("[AuthContext] Logging in user with token:", authToken);
    setUser(userDetails);
    setToken(authToken);
    localStorage.setItem("authToken", authToken);
    localStorage.setItem("userDetails", JSON.stringify(userDetails));
  };

  const logout = () => {
    console.log("[AuthContext] Logging out user");
    setUser(null);
    setToken(null);
    localStorage.removeItem("authToken");
    localStorage.removeItem("userDetails");
  };

  if (loading) {
    return <div>Loading...</div>; // Show a loading state while checking session
  }

  return (
    <AuthContext.Provider value={{ user, token, login, logout, setUser }}>
      {children}
    </AuthContext.Provider>
  );
};
