// import { useNavigation } from "@react-navigation/native";

const BankIdBaseUrl = "https://boxmealadminapinew.azurewebsites.net";
// const BankIdBaseUrl = "http://192.168.0.17:7255";

const checkFridgeUrl =
  "https://boxmealadminapinew.azurewebsites.net/api/fridge";

// Function to fetch the user's IP address
async function fetchIpAddress() {
  try {
    const response = await fetch("https://api64.ipify.org?format=json");
    const data = await response.json();
    if (data.ip) {
      return data.ip;
    }
    throw new Error("IP address not found");
  } catch (error) {
    console.error("Error fetching IP address:", error);
    throw error; // Rethrowing the error to be handled by the caller
  }
}

// Function to authenticate with BankID, including the user's IP address
async function authenticateWithBankID(additionalData = {}) {
  try {
    const userIp = await fetchIpAddress();
    // Include the IP address in the request payload
    const requestData = {
      ...additionalData,
      endUserIp: userIp,
    };

    console.log("requestData: ", requestData);

    const response = await fetch(`${BankIdBaseUrl}/BankId/authenticate`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestData),
    });

    const responseData = await response.json();

    console.log("response data from BankId: ", responseData);

    if (!response.ok) {
      console.error("Authentication failed:", responseData);
      throw new Error(
        `Authentication failed: ${response.status} - ${JSON.stringify(
          responseData
        )}`
      );
    }

    //check if this personal number exists in the user database
    //if exists make a login
    //if doesn't exist go to registration

    return responseData;
  } catch (error) {
    console.error("Error during authentication with BankID:", error);
    throw error; // Rethrowing the error to be handled by the caller
  }
}

async function pollBankIdCollect(orderRef, onCompletion, maxAttempts = 60) {
  let attempts = 0;
  let pollingInterval; // Variable to hold the interval ID

  // Function to stop polling
  const stopPolling = () => {
    if (pollingInterval) {
      clearInterval(pollingInterval);
      console.log(`[BankID Polling] Polling stopped for orderRef: ${orderRef}`);
    }
  };

  // Start polling with setInterval
  pollingInterval = setInterval(async () => {
    if (attempts >= maxAttempts) {
      console.log(
        `[BankID Polling] Max attempts reached for orderRef: ${orderRef}. Stopping.`
      );
      stopPolling(); // Stop polling if max attempts are reached
      return;
    }

    try {
      console.log(
        `[BankID Polling] Polling attempt ${
          attempts + 1
        } for orderRef: ${orderRef}...`
      );

      const response = await fetch(`${BankIdBaseUrl}/BankId/collect`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ orderRef }),
      });

      const responseData = await response.json();

      console.log(
        `[BankID Polling] Response for orderRef: ${orderRef}:`,
        responseData
      );

      if (responseData.status === "complete") {
        console.log(
          `[BankID Polling] Authentication completed successfully for orderRef: ${orderRef}.`
        );
        const personalNumber = responseData.completionData.user.personalNumber;
        console.log("Received personal number:", personalNumber);

        try {
          stopPolling(); // Stop polling after successful completion
          await onCompletion(personalNumber); // Ensure onCompletion is fully handled
          console.log("onCompletion executed successfully");
        } catch (completionError) {
          console.error("Error during onCompletion:", completionError);
        }
      } else if (responseData.status !== "pending") {
        console.error(
          `[BankID Polling] Unexpected status received: ${responseData.status}`
        );
        stopPolling(); // Stop polling on unexpected status
      }
    } catch (error) {
      console.error(
        `[BankID Polling] Error during polling for orderRef: ${orderRef}:`,
        error
      );
      stopPolling(); // Stop polling on error
    }

    attempts++; // Increment attempts after each polling cycle
  }, 1000); // Poll every second
}

const handleUserAuthenticated = (data) => {
  console.log("User successfully authenticated:", data);
  // Handle user data, navigate to next screen, etc.
};

async function registerUser(userData) {
  console.log(
    "Attempting to register user with data:",
    JSON.stringify(userData, null, 2)
  );

  const requestUrl = `${BankIdBaseUrl}/api/auth/register-appuser`;
  console.log("Request URL:", requestUrl);

  try {
    const response = await fetch(requestUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(userData),
    });

    // Immediately log the raw response status and headers
    console.log(`Response status: ${response.status}`, response.headers.map);

    // Read and parse the response JSON/body only once
    const data = await response.json();
    console.log("Response data:", JSON.stringify(data, null, 2));

    if (!response.ok) {
      // Handle non-OK responses from the server
      console.error(
        "Registration request failed with response:",
        JSON.stringify(data, null, 2)
      );
      return {
        success: false,
        error: data.message || "Could not register user.",
      };
    }

    // Handle successful registration
    console.log("Registration successful with token:", data.token);
    return { success: true, token: data.token };
  } catch (error) {
    // Log any error that occurs during the fetch operation
    console.error("Registration request encountered an error:", error);
    return { success: false, error: error.message };
  }
}

async function logout(navigation) {
  // try {
  //   await AsyncStorage.clear(); // Clears all AsyncStorage data
  //   navigation.navigate("LoginScreen"); // Assuming 'LoginScreen' is the route name
  // } catch (error) {
  //   console.error("Failed to logout:", error);
  // }
}

const fetchAndSaveUserDetails = async (token) => {
  try {
    const response = await fetch(`${BankIdBaseUrl}/api/auth/details`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      throw new Error(`Failed to fetch user details: ${response.status}`);
    }

    const userDetailsJson = await response.json();
    if (userDetailsJson) {
      localStorage.setItem("userDetails", JSON.stringify(userDetailsJson));
      return userDetailsJson;
    } else {
      throw new Error("No user details found in the response");
    }
  } catch (error) {
    console.error("Error fetching user details:", error);
    return null;
  }
};

const checkFridgeCode = async (fridgeCode) => {
  const response = await fetch(`${checkFridgeUrl}/${fridgeCode}`);
  if (!response.ok) {
    throw new Error("Invalid fridge code from service");
  }
  return await response.json();
};

export {
  authenticateWithBankID,
  pollBankIdCollect,
  handleUserAuthenticated,
  registerUser,
  logout,
  fetchAndSaveUserDetails,
  checkFridgeCode,
};
