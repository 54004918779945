import React, { useState, useEffect } from "react";
import styled from "styled-components";
import QRCode from "qrcode.react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useCart } from "../context/CartContext";

const BASE_URL_UNLOCKER =
  "https://boxmealadminapinew.azurewebsites.net/api/unitunlocker";

const QRCodeScreen = () => {
  const { setCartItems } = useCart();
  const [unlockCode, setUnlockCode] = useState(null);
  const [secondsLeft, setSecondsLeft] = useState(null);
  const [error, setError] = useState(null);
  const [selectedFridgeId, setSelectedFridgeId] = useState(null);

  const navigate = useNavigate();
  const location = useLocation();
  const { fridgeId } = useParams();

  console.log("[QRCodeScreen] QRCodeScreen loaded.");

  // Log localStorage content before accessing it
  useEffect(() => {
    console.log("[QRCodeScreen] Current localStorage content:", localStorage);
  }, []);

  // Check if fridgeId is passed via route params, location state, or localStorage
  useEffect(() => {
    const fridgeIdFromState = location.state?.selectedFridgeId || fridgeId;
    const fridgeIdFromStorage = localStorage.getItem("selectedFridgeId");

    if (fridgeIdFromState) {
      setSelectedFridgeId(fridgeIdFromState);
    } else if (fridgeIdFromStorage) {
      setSelectedFridgeId(fridgeIdFromStorage);
    } else {
      console.error(
        "[QRCodeScreen] Fridge ID not found. Redirecting to products..."
      );
      navigate("/products");
    }
  }, [location.state, fridgeId, navigate]);

  // Check if the unlock code or countdown has already been set in localStorage
  useEffect(() => {
    const storedUnlockCode = localStorage.getItem("unlockCode");
    const storedExpiryTime = localStorage.getItem("unlockCodeExpiry");

    if (storedUnlockCode && storedExpiryTime) {
      const expiryTime = parseInt(storedExpiryTime, 10);
      const currentTime = Date.now();
      if (currentTime < expiryTime) {
        setUnlockCode(storedUnlockCode);
        setSecondsLeft(Math.floor((expiryTime - currentTime) / 1000));
        return;
      } else {
        // If the QR code has expired, clear it and redirect
        localStorage.removeItem("unlockCode");
        localStorage.removeItem("unlockCodeExpiry");
        navigate("/products");
        return;
      }
    }

    if (selectedFridgeId) {
      fetchUnlockDetails();
    }
  }, [selectedFridgeId, navigate]);

  const fetchUnlockDetails = async () => {
    try {
      const userId = "munir";
      const url = `${BASE_URL_UNLOCKER}/getUnlockDetails/${encodeURIComponent(
        selectedFridgeId
      )}?userId=${encodeURIComponent(userId)}`;
      console.log("[QRCodeScreen] Constructed URL:", url);

      const response = await fetch(url);
      if (!response.ok) {
        throw new Error("Failed to fetch unlock details");
      }

      const responseJson = await response.json();
      setUnlockCode(responseJson.code);

      const validUntil = new Date(responseJson.validUntil).getTime();
      const initialSeconds = Math.floor((validUntil - Date.now()) / 1000);
      setSecondsLeft(initialSeconds > 0 ? initialSeconds : 0);

      // Store unlock code and expiry in localStorage
      localStorage.setItem("unlockCode", responseJson.code);
      localStorage.setItem("unlockCodeExpiry", validUntil.toString());

      console.log(
        "[QRCodeScreen] Unlock code fetched successfully:",
        responseJson.code
      );
    } catch (error) {
      console.error(
        "[QRCodeScreen] Error fetching unlock details:",
        error.message
      );
      setError("Failed to fetch unlock details. Please try again.");
    }
  };

  // Countdown logic
  useEffect(() => {
    if (secondsLeft === 0) {
      console.log(
        "[QRCodeScreen] Time expired, clearing storage, removing cart items, and redirecting to /products."
      );

      // Clear unlockCode and unlockCodeExpiry
      localStorage.removeItem("unlockCode");
      localStorage.removeItem("unlockCodeExpiry");

      // Remove cart items from localStorage
      localStorage.removeItem("cartItems");

      // Clear cart state in context
      setCartItems([]);
      // Redirect to products page
      navigate("/products");
    } else if (secondsLeft > 0) {
      const intervalId = setInterval(() => {
        setSecondsLeft((prev) => (prev > 0 ? prev - 1 : 0));
      }, 1000);

      return () => clearInterval(intervalId);
    }
  }, [secondsLeft, navigate, setCartItems]);

  if (error) {
    return (
      <Container>
        <ErrorText>{error}</ErrorText>
      </Container>
    );
  }

  return (
    <Container>
      <ContentWrapper>
        <TextTack>Tack för ditt köp!</TextTack>
        {secondsLeft !== null && (
          <CountdownText>{secondsLeft} seconds</CountdownText>
        )}
        {unlockCode && (
          <QRCodeWrapper>
            <QRCode
              value={unlockCode}
              size={200}
              bgColor="white"
              fgColor="black"
            />
          </QRCodeWrapper>
        )}
        <TextTack>
          Rikta QR-koden mot kameralinsen för att öppna enheten
        </TextTack>
      </ContentWrapper>
      <BottomWrapper>
        <BackButton onClick={() => navigate("/products")}>Tillbaka</BackButton>
      </BottomWrapper>
    </Container>
  );
};

export default QRCodeScreen;

// Styled components

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-height: 100vh;
  background-color: black;
  padding: 20px;
  box-sizing: border-box;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  margin-bottom: 20px;
  text-align: center;
`;

const QRCodeWrapper = styled.div`
  margin: 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TextTack = styled.div`
  font-size: 22px;
  color: #fff;
  padding: 10px;
  text-align: center;
  @media (min-width: 768px) {
    font-size: 26px;
  }
`;

const CountdownText = styled.div`
  font-size: 18px;
  color: white;
  margin-bottom: 20px;
  @media (min-width: 768px) {
    font-size: 24px;
  }
`;

const ErrorText = styled.div`
  color: red;
  font-size: 18px;
  text-align: center;
`;

const BottomWrapper = styled.div`
  width: 100%;
  padding: 10px 0;
  position: sticky;
  bottom: 0;
  background-color: black;
  display: flex;
  justify-content: center;
`;

const BackButton = styled.button`
  background-color: #3fc48a;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  padding: 15px 50px;
  border-radius: 30px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
  width: 100%;
  max-width: 300px;

  &:hover {
    background-color: #34a972;
  }
`;
