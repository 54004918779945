// postPaymentServices.js

export const updateFridgeProductQuantities = async (
  cartItems,
  selectedFridgeId
) => {
  try {
    const updates = cartItems.map((item) => ({
      productId: item.id,
      fridgeId: selectedFridgeId,
      quantity: item.quantity,
    }));

    const response = await fetch(
      "https://boxmealadminapinew.azurewebsites.net/api/Fridge/update-quantities",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(updates),
      }
    );

    if (!response.ok) {
      throw new Error("Failed to update fridge product quantities");
    }
  } catch (error) {
    console.error("Error updating fridge product quantities:", error.message);
  }
};

export const saveShoppingHistory = async (
  cartItems,
  user,
  selectedFridgeId,
  token
) => {
  const shoppingHistoryDTO = {
    userId: user.id,
    fridgeId: selectedFridgeId,
    purchaseDate: new Date().toISOString(),
    totalPrice: cartItems.reduce(
      (acc, item) => acc + item.price * item.quantity,
      0
    ),
    items: cartItems.map((item) => ({
      productId: item.id,
      productName: item.title,
      quantity: item.quantity,
      price: item.price,
    })),
  };

  try {
    const response = await fetch(
      `https://boxmealadminapinew.azurewebsites.net/api/ShoppingHistory/shoppinghistory`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(shoppingHistoryDTO),
      }
    );

    if (!response.ok) {
      throw new Error("Failed to save shopping history");
    }

    return await response.json(); // Returning the saved history
  } catch (error) {
    console.error("Error in saveShoppingHistory:", error.message);
  }
};
