import React, { useState } from "react";
import styled from "styled-components";
import dealsImage from "../assets/images/deals.png";
import dryckImage from "../assets/images/categories/dryck.png";
import snackImage from "../assets/images/categories/snacks.png";
import godisImage from "../assets/images/categories/godis.png";
import fikaImage from "../assets/images/categories/fika.png";

const categories = [
  { id: "all", title: "Alla", image: dealsImage },
  { id: "1", title: "Dryck", image: dryckImage },
  { id: "5", title: "Snacks", image: snackImage },
  { id: "6", title: "Godis", image: fikaImage },
  { id: "4", title: "Fika", image: godisImage },
];

const HorizontalCategoryList = ({ onCategorySelect }) => {
  const [selectedCategoryId, setSelectedCategoryId] = useState("all");

  const handleCategoryClick = (categoryId) => {
    setSelectedCategoryId(categoryId);
    onCategorySelect(categoryId);
  };

  return (
    <Wrapper>
      <CategoryList>
        {categories.map((item) => (
          <CategoryContainer
            key={item.id}
            $isSelected={item.id === selectedCategoryId}
            onClick={() => handleCategoryClick(item.id)}
          >
            <CategoryImage
              src={item.image}
              alt={item.title}
              $isSelected={item.id === selectedCategoryId}
            />
            <TextContainer>
              <OverlayText $isSelected={item.id === selectedCategoryId}>
                {item.title}
              </OverlayText>
            </TextContainer>
          </CategoryContainer>
        ))}
      </CategoryList>
    </Wrapper>
  );
};

export default HorizontalCategoryList;

const Wrapper = styled.div`
  padding-top: 20px;
  padding-bottom: 10px;
  background-color: #1a1a1a;
  overflow-x: auto;
  white-space: nowrap; /* Ensures horizontal scroll for items */
  border-radius: 10px 10px 0 0; /* Rounds the top corners */
  display: flex;
  align-items: center; /* Center items vertically */
  &::-webkit-scrollbar {
    display: none; /* Hides the scrollbar */
  }

  position: sticky;
  top: 0;
  z-index: 1000; /* Ensure it's on top of other elements */
`;

const CategoryList = styled.div`
  display: flex;
  padding: 0 10px;
`;

const CategoryContainer = styled.div`
  width: 80px;
  height: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 5px;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
  transform: ${({ $isSelected }) => ($isSelected ? "scale(1.1)" : "none")};
  padding: 3px;
  position: relative;
`;

const CategoryImage = styled.img`
  width: 70px;
  height: 70px;
  border-radius: 50%; /* Ensures the image is circular */
  object-fit: cover; /* Ensures the image maintains aspect ratio without distortion */
  // border: 3px solid
  //   ${({ $isSelected }) => ($isSelected ? "#61dafb" : "#3fc48a")};
  transition: border-color 0.3s ease-in-out, transform 0.3s ease-in-out;

  &:hover {
    border-color: #61dafb;
  }
`;

const TextContainer = styled.div`
  margin-top: 5px;
  text-align: center;
  width: 100%;
`;

const OverlayText = styled.span`
  color: ${({ $isSelected }) => ($isSelected ? "#61dafb" : "#fff")};
  font-size: 12px; /* Reduced font size to fit better */
  font-weight: bold;
  text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.75);
  transition: color 0.3s ease-in-out;
  white-space: nowrap; /* Prevents text from wrapping */
  overflow: hidden; /* Prevents overflowing text */
  text-overflow: ellipsis; /* Adds ellipsis if text is too long */
`;
