// src/components/Layout.js
import React from "react";
import BottomMenu from "./BottomMenu";
import styled from "styled-components";

const Layout = ({ children }) => {
  return (
    <LayoutContainer>
      <Content>{children}</Content>
      <BottomMenuContainer>
        <BottomMenu />
      </BottomMenuContainer>
    </LayoutContainer>
  );
};

export default Layout;

const LayoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
`;

const Content = styled.div`
  flex: 1; /* Take up the remaining space above the BottomMenu */
  padding-bottom: 60px; /* Add padding to avoid content being hidden behind BottomMenu */
`;

const BottomMenuContainer = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 1000;
`;
