import React, { useState } from "react";
import styled from "styled-components";
import logoSrc from "../assets/images/logo.png"; // Replace with the correct path to your logo image

const EmailPage = () => {
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  const handleSendEmail = () => {
    if (email.trim() === "" || subject.trim() === "" || message.trim() === "") {
      alert("Please fill in all fields.");
      return;
    }

    // Logic to send the email would go here.
    console.log("Email sent:", { email, subject, message });

    // Clear the form after sending the email
    setEmail("");
    setSubject("");
    setMessage("");
  };

  return (
    <Container>
      <Header>
        <Logo src={logoSrc} alt="Logo" />
        <Title>Email Us</Title>
      </Header>
      <FormContainer>
        <Input
          type="email"
          placeholder="Your email address"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <Input
          type="text"
          placeholder="Subject"
          value={subject}
          onChange={(e) => setSubject(e.target.value)}
        />
        <MessageInput
          placeholder="Type your message..."
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        />
        <SendButton onClick={handleSendEmail}>Send Email</SendButton>
      </FormContainer>
    </Container>
  );
};

export default EmailPage;

// Styled components

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #1a1a1a;
  color: #ffffff;
  height: 100vh;
  width: 100%;
  max-width: 600px;
  margin: auto;
  padding: 20px;
  box-sizing: border-box;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 40px; /* Increased margin for better spacing */
`;

const Logo = styled.img`
  width: 100px;
  margin-bottom: 10px;
`;

const Title = styled.h1`
  font-size: 24px;
  color: #3fc48a;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #252525;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  margin-bottom: 20px;
  margin-top: auto; /* This ensures the form is pushed up */
  margin-bottom: auto; /* This ensures the form is pushed up */
`;

const Input = styled.input`
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: #2c2c2c;
  color: #ffffff;
  font-size: 16px;
  margin-bottom: 15px;
  outline: none;
`;

const MessageInput = styled.textarea`
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: #2c2c2c;
  color: #ffffff;
  font-size: 16px;
  height: 300px;
  margin-bottom: 15px;
  resize: none;
  outline: none;
`;

const SendButton = styled.button`
  background-color: #3fc48a;
  border: none;
  border-radius: 5px;
  padding: 15px;
  cursor: pointer;
  color: #ffffff;
  font-size: 18px;
  font-weight: bold;
  text-align: center;

  &:hover {
    background-color: #34b380;
  }
`;
