import React, { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import { getAllProducts } from "../services/productService";
import ProductItem from "../components/ProductItem";
import HorizontalCategoryList from "../components/HorizontalCategoryList";
import TopMenu from "../components/TopMenu";
import BottomMenu from "../components/BottomMenu";
import { useCart } from "../context/CartContext";

const ProductsScreen = () => {
  const [selectedFridge, setSelectedFridge] = useState(() => {
    return localStorage.getItem("selectedFridgeId") || null;
  });
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const { cartItems, setCartItems } = useCart();
  const [selectedQuantities, setSelectedQuantities] = useState({});
  const [availableQuantities, setAvailableQuantities] = useState({});
  const [selectedCategory, setSelectedCategory] = useState("all");
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState("");

  useEffect(() => {
    if (selectedFridge) {
      setLoading(true);
      localStorage.setItem("selectedFridgeId", selectedFridge); // Store fridge ID in localStorage
      getAllProducts(selectedFridge)
        .then((productsFromAPI) => {
          const adaptedProducts = productsFromAPI.map((product) => ({
            id: product.id,
            title: product.productName,
            imageURL: product.imageUrl,
            price: product.customSalesPrice || product.defaultSalesPrice,
            description: product.description,
            categoryId: product.categoryId,
            quantity: 1,
            availableQuantity: product.quantity,
          }));

          const initialSelectedQuantities = {};
          const initialAvailableQuantities = {};
          adaptedProducts.forEach((product) => {
            initialSelectedQuantities[product.id] = 1;
            initialAvailableQuantities[product.id] = product.availableQuantity;
          });

          setProducts(adaptedProducts);
          setFilteredProducts(adaptedProducts);
          setSelectedQuantities(initialSelectedQuantities);
          setAvailableQuantities(initialAvailableQuantities);
        })
        .catch((error) => {
          console.error("Failed to fetch products", error);
          alert("Error: Failed to fetch products.");
        })
        .finally(() => setLoading(false));
    } else {
      alert("Please select a fridge.");
    }
  }, [selectedFridge]);

  const handleFridgeChange = (fridgeId) => {
    setSelectedFridge(fridgeId);
    localStorage.setItem("selectedFridgeId", fridgeId); // Store fridge ID whenever it's changed
  };

  const handleCategorySelect = (categoryId) => {
    setSelectedCategory(categoryId);
    if (categoryId === "all") {
      setFilteredProducts(products);
    } else {
      const filtered = products.filter(
        (product) => product.categoryId.toString() === categoryId
      );
      setFilteredProducts(filtered);
    }
  };

  const updateProductQuantity = (productId, newQuantity) => {
    setSelectedQuantities((prevQuantities) => ({
      ...prevQuantities,
      [productId]: newQuantity,
    }));
  };

  const handleAddToCart = (product, quantityToAdd) => {
    if (quantityToAdd > selectedQuantities[product.id]) {
      alert(
        `Cannot add more than selected quantity. Selected: ${
          selectedQuantities[product.id]
        }`
      );
      return;
    }

    const itemInCart = cartItems.find((item) => item.id === product.id);
    if (itemInCart) {
      setCartItems((prevItems) =>
        prevItems.map((item) =>
          item.id === product.id
            ? { ...item, quantity: item.quantity + quantityToAdd }
            : item
        )
      );
    } else {
      setCartItems((prevItems) => [
        ...prevItems,
        { ...product, quantity: quantityToAdd },
      ]);
    }
  };

  const handleInfoClick = (description) => {
    setModalContent(description);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <ScreenContainer>
      <TopMenu onFridgeSelect={handleFridgeChange} />
      <StickyContainer>
        <HorizontalCategoryList onCategorySelect={handleCategorySelect} />
      </StickyContainer>
      <ScrollableContent>
        {loading ? (
          <CenteredView>Loading products...</CenteredView>
        ) : filteredProducts.length === 0 ? (
          <CenteredView>
            No products available. Please select the fridge from the list
          </CenteredView>
        ) : (
          <ProductList>
            {filteredProducts.map((item) => (
              <ProductCard key={item.id}>
                <ProductItem
                  product={item}
                  selectedQuantity={selectedQuantities[item.id]}
                  availableQuantity={availableQuantities[item.id]}
                  onQuantityChange={(newQuantity) =>
                    updateProductQuantity(item.id, newQuantity)
                  }
                  onAddToCart={() =>
                    handleAddToCart(item, selectedQuantities[item.id])
                  }
                  onInfoClick={() => handleInfoClick(item.description)}
                />
              </ProductCard>
            ))}
          </ProductList>
        )}
      </ScrollableContent>
      {showModal && (
        <ModalOverlay onClick={handleCloseModal}>
          <ModalContent onClick={(e) => e.stopPropagation()}>
            <CloseButton onClick={handleCloseModal}>×</CloseButton>
            <ModalText>{modalContent}</ModalText>
          </ModalContent>
        </ModalOverlay>
      )}
      <BottomMenuSpacer />
      <BottomMenuContainer>
        <BottomMenu selectedFridgeId={selectedFridge} />
      </BottomMenuContainer>
    </ScreenContainer>
  );
};

export default ProductsScreen;

const StickyContainer = styled.div`
  position: sticky;
  top: 0;
  z-index: 1000;
  background-color: #1a1a1a; /* Background color to make it look good */
  padding: 10px 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.6); /* Slight shadow for visual separation */
  width: 100%; /* Ensure it spans the full width */
`;

const ScrollableContent = styled.div`
  flex: 1;
  overflow-y: auto; /* Keep vertical scrolling */
  background-color: #1a1a1a;
  padding: 10px;
  margin-top: 10px;
  box-sizing: border-box;
  position: relative; /* Ensure relative positioning for sticky context */
`;

const ScreenContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Ensure it covers the entire viewport */
  background-color: #1a1a1a;
  overflow-y: auto; /* Enable scrolling */
`;

const CenteredView = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: #ffffff;
  font-size: 18px;
  text-align: center;
  margin-top: 40px;
`;

const ProductList = styled.div`
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
  gap: 20px;
  padding: 0 10px;

  @media (min-width: 576px) {
    grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
  }

  @media (min-width: 768px) {
    grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  }

  @media (min-width: 1024px) {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }

  @media (min-width: 1440px) {
    grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
  }
`;

const ProductCard = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100%;
`;

const BottomMenuContainer = styled.div`
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
`;

const BottomMenuSpacer = styled.div`
  height: 70px; /* Adjust the height to match the BottomMenu's height */
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: scale(0.8);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background: #1a1a1a;
  padding: 16px;
  border-radius: 10px;
  max-width: 400px;
  width: 90%;
  text-align: center;
  color: #ffffff;
  position: relative;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 8px;
  right: 8px;
  background: transparent;
  border: none;
  color: #ffffff;
  font-size: 19.2px;
  cursor: pointer;
`;

const ModalText = styled.p`
  font-size: 14px;
  line-height: 1.5;
`;
