const BASE_URL =
  "https://boxmealadminapinew.azurewebsites.net/api/ShoppingCart";

const handleResponse = async (response) => {
  if (!response.ok) {
    const errorText = await response.text();
    console.error("HTTP Error Response:", errorText);
    throw new Error(`HTTP Error: ${response.status} - ${errorText}`);
  }

  try {
    const jsonResponse = await response.json();
    console.log("Response JSON:", jsonResponse);
    return jsonResponse;
  } catch (error) {
    console.error("Error parsing JSON:", error);
    throw new Error("Error parsing response JSON");
  }
};

const handleError = (error) => {
  console.error("API Call Error:", error);
  alert(`Error: ${error.message || "Something went wrong"}`);
  throw error; // Ensure that the error propagates up after logging
};

export const initiateSwishPayment = async (paymentData) => {
  console.log("paymentData passed to initiateSwishPayment: ", paymentData);
  const endpoint = `${BASE_URL}/initiate`;
  console.log("Initiating Swish Payment:", endpoint, paymentData);

  try {
    const response = await fetch(endpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(paymentData),
    });
    return await handleResponse(response);
  } catch (error) {
    handleError(error);
  }
};

export const getSwishPaymentStatus = async (paymentRequestId) => {
  const endpoint = `${BASE_URL}/GetSwishPaymentStatus/${paymentRequestId}`;
  console.log("Fetching Swish payment status:", endpoint);

  try {
    const response = await fetch(endpoint, {
      method: "GET",
    });
    return await handleResponse(response);
  } catch (error) {
    console.error("Error getting Swish payment status:", error);
    handleError(error);
  }
};
