import React from "react";
import styled from "styled-components";

const PLACEHOLDER_IMAGE = "https://via.placeholder.com/150";

const ProductItem = ({
  product,
  selectedQuantity,
  availableQuantity,
  onQuantityChange,
  onAddToCart,
  onInfoClick,
}) => {
  const handleDecrease = () => {
    if (selectedQuantity > 1) {
      onQuantityChange(selectedQuantity - 1);
    }
  };

  const handleIncrease = () => {
    if (selectedQuantity < availableQuantity) {
      onQuantityChange(selectedQuantity + 1);
    } else {
      alert("Cannot add more than the available quantity");
    }
  };

  const handleChangeText = (text) => {
    const newQuantity = Number(text);
    if (newQuantity >= 1 && newQuantity <= availableQuantity) {
      onQuantityChange(newQuantity);
    } else {
      alert("Enter a valid quantity");
    }
  };

  return (
    <Container>
      <ImageContainer>
        <ProductImage
          src={product.imageURL}
          onError={(e) => {
            console.warn("Image loading error:", e);
            e.target.src = PLACEHOLDER_IMAGE;
          }}
          alt={product.title}
        />
        <InfoIcon onClick={onInfoClick}>i</InfoIcon>
      </ImageContainer>
      <ProductDetails>
        <ProductTitle>{product.title || "No title"}</ProductTitle>
        <ProductPrice>{product.price || 0} kr</ProductPrice>
      </ProductDetails>
      <ButtonContainer>
        <QuantityButton onClick={handleDecrease}>-</QuantityButton>
        <QuantityLabel>{selectedQuantity}</QuantityLabel>
        <QuantityButton onClick={handleIncrease}>+</QuantityButton>
      </ButtonContainer>
      <ShopButtonContainer>
        <ShopButton onClick={() => onAddToCart(product, selectedQuantity)}>
          Köp
        </ShopButton>
      </ShopButtonContainer>
    </Container>
  );
};

export default ProductItem;

const Container = styled.div`
  width: 100%;
  padding: 10px; /* Reduce padding to adjust the overall size */
  border: 1px solid #444;
  border-radius: 12px;
  // background-color: #252525;
  background-color: #252525;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.8);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  overflow: hidden;
  text-align: center;
  height: 100%;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.8);
  }
`;

const ImageContainer = styled.div`
  width: 100%;
  height: 0;
  padding-bottom: 90%; /* Adjust aspect ratio */
  margin-bottom: 10px; /* Reduce margin */
  border-radius: 8px;
  overflow: hidden;
  position: relative;
`;

const ProductImage = styled.img`
  position: absolute;
  top: 8px; /* Reduced by 20% */
  left: 8px; /* Reduced by 20% */
  right: 8px; /* Reduced by 20% */
  bottom: 8px; /* Reduced by 20% */
  object-fit: contain;
  width: calc(100% - 16px); /* Adjusted for new padding */
  height: calc(100% - 16px); /* Adjusted for new padding */
  border-radius: 4px; /* Slightly reduced */
  background-color: transparent;
`;

const InfoIcon = styled.div`
  position: absolute;
  bottom: 8px; /* Reduced by 20% */
  right: 8px; /* Reduced by 20% */
  width: 19px; /* Reduced by 20% */
  height: 19px; /* Reduced by 20% */
  background-color: #3fc48a;
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 13px; /* Reduced by 20% */
  font-weight: bold;
`;

const ProductDetails = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px; /* Reduced by 20% */
`;

const ProductTitle = styled.h3`
  font-size: 14px; /* Reduced by 20% */
  color: #ffffff;
  text-align: center;
`;

const ProductPrice = styled.p`
  font-size: 18px; /* Reduced by 20% */
  font-weight: bold;
  color: #3fc48a;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
  padding: 0 5px; /* Adjusted padding for left and right */
`;

const QuantityButton = styled.button`
  width: 40px; /* Ensuring it's a perfect circle */
  height: 40px; /* Ensuring it's a perfect circle */
  border-radius: 50%; /* Ensures it remains circular */
  background-color: #333;
  color: #fff;
  font-size: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #3fc48a;
  cursor: pointer;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: #3fc48a;
    color: #333;
  }

  /* Ensuring consistent size on smaller and larger screens */
  @media (max-width: 576px) {
    width: 35px;
    height: 35px;
    font-size: 20px;
  }

  @media (min-width: 1024px) {
    width: 45px;
    height: 45px;
    font-size: 24px;
  }
`;

const QuantityLabel = styled.span`
  font-size: 20px;
  color: #fff;
  font-weight: bold;
  text-align: center;
  width: 50px;
  display: inline-block;
`;

const ShopButtonContainer = styled.div`
  width: 100%;
  margin-top: auto;
`;

const ShopButton = styled.button`
  background-color: #3fc48a;
  padding: 10px 15px; /* Adjusted padding for top, bottom, left, and right */
  border-radius: 25px;
  color: #ffffff;
  font-size: 16px;
  font-weight: bold;
  width: 100%;
  cursor: pointer;
  border: none;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: #34a972;
  }
`;
