const API_URL = "https://boxmealadminapinew.azurewebsites.net/api/chat";
// const API_URL = "https://localhost:7255/api/chat";

export const fetchMessagesByUserId = async (token, userId) => {
  if (!token) {
    throw new Error("No token provided for fetching messages.");
  }

  const response = await fetch(
    `${API_URL}/getAllMessagesByUserId?userId=${userId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }
  );

  if (!response.ok) {
    console.error("Failed to fetch messages, response:", response);
    throw new Error("Failed to fetch messages");
  }

  return await response.json();
};

export const sendReply = async (messageObject, token) => {
  if (!token) {
    throw new Error("No token found in local storage");
  }

  const response = await fetch(`${API_URL}/sendReply`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(messageObject),
  });

  if (!response.ok) {
    console.error("Failed to send reply, response:", response);
    throw new Error("Failed to send reply");
  }

  return await response.json();
};

export const createSession = async (token, userId) => {
  if (!token) {
    throw new Error("No token found in local storage");
  }

  const response = await fetch(`${API_URL}/createSession`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ userId, adminId: "admin@boxmeal.se" }),
  });

  if (!response.ok) {
    console.error("Failed to create session, response:", response);
    throw new Error("Failed to create session");
  }

  return await response.json();
};

export const getSessionByUserId = async (token, userId) => {
  if (!token) {
    throw new Error("No token found in local storage");
  }

  const response = await fetch(
    `${API_URL}/getSessionsByUserId?userId=${userId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }
  );

  if (!response.ok) {
    console.error("Failed to fetch session, response:", response);
    throw new Error("Failed to fetch session");
  }

  return await response.json();
};
