import React from "react";
import styled from "styled-components";

const CartItem = ({ imageURL, title, price, quantity, onRemove }) => {
  const itemTotal = price * quantity;

  return (
    <CartItemContainer>
      <ProductImage src={imageURL} alt={title} />
      <DetailsContainer>
        <ItemTitle>{title}</ItemTitle>
        <ItemText>
          {quantity} x {price} kr
        </ItemText>
      </DetailsContainer>
      <ItemInfo>
        <RemoveButton onClick={onRemove}>X</RemoveButton>
        <ItemTotal>{itemTotal} kr</ItemTotal>
      </ItemInfo>
    </CartItemContainer>
  );
};

export default CartItem;

const CartItemContainer = styled.div`
  display: flex;
  align-items: center;
  height: 120px;
  padding: 15px;
  margin-bottom: 10px;
  background-color: #1a1a1a;
  border-radius: 12px;
  border: 1px solid #444;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.2s ease-in-out;
  position: relative; /* Make the container relative for absolute positioning of RemoveButton */

  &:hover {
    transform: scale(1.02);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
  }
`;

const ProductImage = styled.img`
  width: 80px;
  height: 80px;
  object-fit: contain;
  border-radius: 8px;
`;

const DetailsContainer = styled.div`
  flex: 2;
  display: flex;
  flex-direction: column;
`;

const ItemInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: relative; /* Make ItemInfo relative for precise positioning */
`;

const ItemTitle = styled.div`
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 4px;
`;

const ItemText = styled.div`
  color: #ccc;
  font-size: 16px;
`;

const ItemTotal = styled.div`
  color: #3fc48a;
  font-size: 26px; /* Make the price text larger */
  font-weight: bold;
  margin-top: 15px; /* Added margin top to separate from Remove button */
`;

const RemoveButton = styled.button`
  background-color: transparent;
  color: #fff;
  border: 2px solid #fff;
  border-radius: 50%;
  padding: 6px 10px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.2s ease, color 0.2s ease;
  position: absolute;
  top: -25px; /* Move the button further up */
  right: -10px;

  &:hover {
    background-color: #fff;
    color: #3fc48a;
  }
`;
