import React, { useEffect } from "react";
import styled from "styled-components";
import {
  authenticateWithBankID,
  pollBankIdCollect,
  fetchAndSaveUserDetails,
} from "../services/ApiService";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import boxmealLogoSrc from "../assets/images/logo.png";
import bankIdLogoSrc from "../assets/images/BankID_logo_white.png";
import videoSrc from "../assets/onboarding/video2.mp4";

const BankIdBaseUrl = "https://boxmealadminapinew.azurewebsites.net";

const OnboardingScreenVideo = () => {
  const navigate = useNavigate();
  const { login } = useAuth();
  const { token, loading } = useAuth();

  useEffect(() => {
    if (!loading && token) {
      console.log("User already has a token, redirecting to /products");
      navigate("/products");
    }
  }, [navigate, token, loading]);

  const handleButtonPress = async (e) => {
    e.preventDefault();
    console.log("Button pressed");

    try {
      const authResponse = await authenticateWithBankID();
      console.log("Authentication successful:", authResponse);

      if (authResponse?.orderRef && authResponse?.autoStartToken) {
        console.log("Attempting to open the BankID app...");
        handleBankIdOpen(authResponse.autoStartToken);

        console.log("Polling for BankID...");
        await pollBankIdCollect(
          authResponse.orderRef,
          async (personalNumber) => {
            console.log(
              "Received personal number from BankID:",
              personalNumber
            );

            try {
              const response = await fetch(
                `${BankIdBaseUrl}/api/auth/login-or-register`,
                {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                    "User-Agent": "BoxmealAdmin/1.0 (+https://handla.friky.se)",
                  },
                  body: JSON.stringify({ personalNumber }),
                }
              );

              const data = await response.json();
              console.log("Response from /login-or-register:", data);

              if (!response.ok) {
                console.error("Error from backend:", data);
                if (data.message === "User does not exist. Please register.") {
                  console.log("Navigating to registration page.");
                  navigate("/registration");
                } else {
                  console.error("Unexpected error:", data.message);
                }
              } else {
                console.log("User exists, token received:", data.token);
                localStorage.setItem("authToken", data.token);

                // Fetch and save user details
                const userDetails = await fetchAndSaveUserDetails(data.token);
                if (userDetails) {
                  login(userDetails, data.token); // Use the Auth context to login
                  console.log(
                    "Token and user details saved, navigating to ProductsScreen"
                  );
                  navigate("/products");
                } else {
                  console.error("Failed to fetch user details.");
                }
              }
            } catch (error) {
              console.error("Error during fetch:", error);
            }
          }
        );
      } else {
        console.error(
          "Authentication did not return an orderRef or autoStartToken"
        );
      }
    } catch (error) {
      console.error("Authentication or polling failed:", error);
    }
  };

  const handleBankIdOpen = (autoStartToken) => {
    const returnUrl = "https://handla.friky.se/products";
    const bankIdUrl = `https://app.bankid.com/?autostarttoken=${autoStartToken}`;
    try {
      console.log("Opening BankID app with token:", autoStartToken);
      document.referrerPolicy = "origin";
      window.open(bankIdUrl, "_self");
    } catch (error) {
      console.error("Failed to open BankID URL:", error);
    }
  };

  return (
    <Container>
      <VideoWrapper>
        <BackgroundVideo src={videoSrc} autoPlay muted loop playsInline />
      </VideoWrapper>
      <ContentContainer>
        <TopLogoContainer>
          <TopLogo src={boxmealLogoSrc} alt="Boxmeal Logo" />
        </TopLogoContainer>
        <ButtonContainer>
          <LoginButton onClick={handleButtonPress}>
            <Logo src={bankIdLogoSrc} alt="BankID Logo" />
            <ButtonText>Logga in med BankID</ButtonText>
          </LoginButton>
        </ButtonContainer>
      </ContentContainer>
    </Container>
  );
};

export default OnboardingScreenVideo;

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const VideoWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const BackgroundVideo = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const ContentContainer = styled.div`
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
`;

const TopLogoContainer = styled.div`
  width: 60%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const TopLogo = styled.img`
  width: 60%;
`;

const ButtonContainer = styled.div`
  width: 80%;
  display: flex;
  justify-content: center;
  margin-bottom: 100px;
`;

const LoginButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  background-color: #3fc48a;
  border-radius: 100px;
  width: 100%;
  max-width: 350px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #3fc48a;
  }
`;

const ButtonText = styled.span`
  color: white;
  font-size: 18px;
  margin-left: 10px;
`;

const Logo = styled.img`
  width: 50px;
  height: 50px;
`;
