import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useLocation, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faSort } from "@fortawesome/free-solid-svg-icons";

const PaymentHistory = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { user, token } = location.state || {};
  const [history, setHistory] = useState([]);
  const [sortOrder, setSortOrder] = useState("desc"); // Default to descending order

  useEffect(() => {
    if (user) {
      fetchPaymentHistory(user.id);
    }
  }, [user]);

  const fetchPaymentHistory = async (userId) => {
    try {
      const response = await fetch(
        `https://boxmealadminapinew.azurewebsites.net/api/ShoppingHistory/${userId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`, // Ensure to pass the user's token if required
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch payment history");
      }

      const data = await response.json();
      sortHistory(data, sortOrder); // Sort data based on the default or selected sortOrder
    } catch (error) {
      console.error("Error fetching payment history:", error);
    }
  };

  const sortHistory = (data, order) => {
    const sortedHistory = data.sort((a, b) => {
      return order === "desc"
        ? new Date(b.purchaseDate) - new Date(a.purchaseDate)
        : new Date(a.purchaseDate) - new Date(b.purchaseDate);
    });
    setHistory(sortedHistory);
  };

  const toggleSortOrder = () => {
    const newOrder = sortOrder === "desc" ? "asc" : "desc";
    setSortOrder(newOrder);
    sortHistory(history, newOrder);
  };

  const formatDateTime = (dateTime) => {
    const date = new Date(dateTime);
    return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
      2,
      "0"
    )}-${String(date.getDate()).padStart(2, "0")} ${date.toLocaleTimeString(
      "en-GB"
    )}`;
  };

  return (
    <Container>
      <Header>
        <Title>Payment History</Title>
        <SortButton onClick={toggleSortOrder}>
          Sort by Date <FontAwesomeIcon icon={faSort} />
        </SortButton>
      </Header>
      <HistoryContainer>
        {history.length > 0 ? (
          history.map((record, index) => (
            <HistoryItem key={index}>
              <HistoryDate>{formatDateTime(record.purchaseDate)}</HistoryDate>
              <HistoryTotal>{record.totalPrice} SEK</HistoryTotal>
              <ItemsList>
                {record.items.map((item, i) => (
                  <Item key={i}>
                    {item.productName} - {item.quantity} x {item.price} SEK
                  </Item>
                ))}
              </ItemsList>
            </HistoryItem>
          ))
        ) : (
          <NoHistory>No payment history found</NoHistory>
        )}
      </HistoryContainer>
      <StickyFooter>
        <BackButton onClick={() => navigate(-1)}>
          <FontAwesomeIcon icon={faArrowLeft} /> Tillbaka
        </BackButton>
      </StickyFooter>
    </Container>
  );
};

export default PaymentHistory;

// Styled components...

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #1a1a1a;
  color: white;
  min-height: 100vh;
  padding-bottom: 70px; /* Space for the sticky footer */
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 600px;
  margin: 20px 0;
  padding: 0 20px;
`;

const Title = styled.h1`
  font-size: 28px;
  color: #3fc48a;
`;

const SortButton = styled.button`
  background-color: transparent;
  color: #3fc48a;
  border: none;
  font-size: 18px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
  transition: color 0.3s ease;

  &:hover {
    color: #34a972;
  }

  svg {
    margin-left: 5px;
  }
`;

const HistoryContainer = styled.div`
  width: 100%;
  max-width: 600px;
  flex: 1;
  overflow-y: auto;
  padding: 0 10px;
`;

const HistoryItem = styled.div`
  background-color: #252525;
  padding: 15px;
  border-radius: 15px;
  margin-bottom: 20px;
  width: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  transition: transform 0.2s ease, box-shadow 0.2s ease;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.8);
  }
`;

const HistoryDate = styled.div`
  font-size: 18px;
  color: #bbbbbb;
  margin-bottom: 10px;
`;

const HistoryTotal = styled.div`
  font-size: 22px;
  color: #3fc48a;
  font-weight: bold;
  margin-bottom: 10px;
`;

const ItemsList = styled.ul`
  list-style: none;
  padding-left: 0;
  margin: 0;
`;

const Item = styled.li`
  font-size: 16px;
  color: #dddddd;
  margin-bottom: 5px;
`;

const NoHistory = styled.div`
  font-size: 18px;
  color: #bbbbbb;
  margin-top: 50px;
  text-align: center;
`;

const StickyFooter = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  max-width: 600px;
  background-color: rgba(26, 26, 26, 0.9);
  padding: 15px;
  display: flex;
  justify-content: center;
  border-top: 1px solid #3fc48a;
`;

const BackButton = styled.button`
  background-color: #3fc48a;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  padding: 15px 30px;
  border-radius: 30px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
  display: flex;
  align-items: center;

  &:hover {
    background-color: #34a972;
  }

  svg {
    margin-right: 8px;
  }
`;
